import { SectionSchema, SerializableSectionSchema } from '@homeflow/next/sections';

/**
 * We are storing the schemas in redux but redux does not allow non-serializable
 * fields in state. Strip out `icon` and `clientComponent` fields as these are
 * function components so not serializable.
 */
export function omitNonSerializableFromSchemas(schemas: { [key: string]: SectionSchema }) {
  const filteredSchemas: any = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [name, schema] of Object.entries(schemas)) {
    // eslint-disable-next-line no-restricted-syntax
    const { icon, clientComponent, initialConfig, settingsSummary, ...serializableFields } = schema;
    filteredSchemas[name] = serializableFields;
  }

  return filteredSchemas as { [key: string]: Omit<SectionSchema, 'clientComponent' | 'icon'> };
}

export function omitNonSerializableFromSchema(schema: SectionSchema) {
  const { icon, clientComponent, initialConfig, settingsSummary, ...serializableFields } = schema;

  return serializableFields as SerializableSectionSchema;
}
