import { ElementType } from '@homeflow/next/state';
import { createContext } from 'react';

export type ElementLimits = { [key in ElementType]: boolean };

type Context = {
  openAddElementModal: boolean;
  onOpenAddElement: () => void;
  onCloseAddElement: () => void;
  handleSetOnAdd: (cb: (type: ElementType) => void) => void;
  handleSetElementLimits: (limits: ElementLimits) => void;
  openRemoveSectionModal: boolean;
  onOpenRemoveSection: () => void;
  onCloseRemoveSection: () => void;
  handleSetOnRemove: (cb: () => void) => void;
};

/**
 * Context provider for the section modal functionality. Provides single entry
 * points for section related modals, eg "Remove section" and "Add element"
 * functionality.
 * Creates a single `<div>` for the `AdminModal` to open in the DOM instead of
 * creating a `<div>` for each section / element rendered on the page.
 *
 * @example
 * ```
 * const handleOpen = () => {
 *   handleSetElementLimits(elementLimits);
 *   handleSetOnAdd(handleInsertElement);
 *   onOpen();
 * };
 * ```
 */

const SectionModalContext = createContext<Context>({
  openAddElementModal: false,
  onOpenAddElement: () => null,
  onCloseAddElement: () => null,
  handleSetOnAdd: () => null,
  handleSetElementLimits: () => null,
  openRemoveSectionModal: false,
  onOpenRemoveSection: () => null,
  onCloseRemoveSection: () => null,
  handleSetOnRemove: () => null,
});

export default SectionModalContext;
